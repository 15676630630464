import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../layout/index'

import Image from '../components/Image'
import Heading from '../components/Heading'
import { Link } from 'gatsby'

import { GatsbyImageFluidProps } from 'gatsby-image'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    image: {
      childImageSharp: GatsbyImageFluidProps
    }
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgb(152, 164, 180);
  color: black;
`

export default ({ data, location }: IndexPageProps) => {
  const { image, site } = data
  return (
    <Wrapper>
      <Link to="/">
        <Image img={image.childImageSharp} />
      </Link>
      <Heading
        title="404 ¡No existe la página que intentas buscar!"
        subtitle={site.siteMetadata.description}
      />
    </Wrapper>
  )
}

export const NotFoundQuery = graphql`
  query NotFoundPageQuery {
    image: file(
      relativePath: { eq: "logos/logo-restaurante-borrasco-sombra.png" }
    ) {
      ...fluidImage
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
